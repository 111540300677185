<template>
  <div>
    <!-- Modal del formulario -->
    <div :class="{'modal': true, 'is-active': !showResult}">
      <div class="modal-background"></div>
      <div class="modal-content">
        <div class="box" style="max-width: 450px; margin: auto;">

          <div class="field">
            <label class="label has-text-danger">Oferta del {{ detail.productName }} por parte de GivU:</label>
            <div class="control has-icons-left has-icons-right">
              <input class="input is-medium" style="padding-left: 35px;" type="number" v-model="totalPrice"/>
              <span class="icon is-medium is-left">$</span>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <button class="button is-danger is-outlined is-fullwidth is-rounded is-medium" @click="close">Cerrar</button>
            </div>
            <div class="column">
              <button class="button is-danger is-fullwidth is-rounded is-medium" @click="submit" :disabled="loadingStatus" :class="{ 'is-loading': loadingStatus}">Aceptar</button>
            </div>
          </div>
          <div v-if="error" class="notification is-danger">
            <p>{{ error }}</p>
          </div>
        </div>
      </div>
      <button class="modal-close is-large" aria-label="close" @click="close"></button>
    </div>

    <!-- Modal del resultado -->
    <div :class="{'modal': true, 'is-active': showResult}">
      <div class="modal-background"></div>
      <div class="modal-content">
        <div class="box" style="max-width: 350px; margin: auto;">
          <div class="field">
            <div class="control">
              <p>{{ apiResult }}</p>
            </div>
          </div>
          <div class="field is-grouped is-grouped-right">
            <div class="control">
              <button class="button is-danger is-outlined is-rounded is-medium" @click="close">Cerrar</button>
            </div>
          </div>
        </div>
      </div>
      <button class="modal-close is-large" aria-label="close" @click="close"></button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    detail: {
      type: Object,
      required: true
    },
    close: {
      type: Function,
      default: () => {}
    },
    action: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      totalPrice: null,
      loadingStatus: false,
      apiResult: null,
      error: null,
      showResult: false
    }
  },
  methods: {
    async submit () {
      try {
        this.loadingStatus = true

        if (this.totalPrice <= 0) {
          this.error = 'Precio mayor a 1'
          return
        }

        if (this.totalPrice > 9999999) {
          this.error = 'Precio maximo de 9999999'
          return
        }

        const response = await this.action(this.totalPrice)

        this.apiResult = response

        this.error = null
        this.showResult = true
      } catch (err) {
        this.error = 'Error al realizar la operación. Por favor, inténtalo de nuevo.'
        this.apiResult = null
      } finally {
        this.loadingStatus = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.box {
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
}
.field {
  margin-bottom: 1rem;
}
.button.is-fullwidth {
  width: 100%;
}
.modal-background {
  background-color: rgba(0, 0, 0, 0.5);
}
.is-danger{
  background-color: #EB5965;
}
</style>
